import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectsPage from './ProjectsPage';
import ProjectPage from './ProjectPage';

export default function Projects() {
  return (
    <Routes>
      <Route path='new' element={<ProjectPage />} />
      <Route path='list' element={<ProjectsPage />} />
      <Route path='edit/:projectId' element={<ProjectPage />} />
    </Routes>
  );
}
