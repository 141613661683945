import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import objectPath from 'object-path';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import BreadCrumbs from './components/BreadCrumbs';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import SearchField from '../../../app/components/ui/SearchField';
import ButtonAddProduct from '../../../app/components/ui/Buttons/ButtonAddProduct';
import { makeStyles } from '@material-ui/core';
import AutocompleteLocations from '../../../app/components/AutocompleteLocations';
import { actions as locationActions } from '../../../app/store/ducks/yaLocations.duck';
import { actions as productCatalogActions } from '../../../app/store/ducks/product-catalog.duck';
import { useIntl } from 'react-intl';
import { UserRoles } from '../../../app/interfaces/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAppState } from '../../../app/store/rootDuck';

const useStyles = makeStyles(theme => ({
  cartText: {
    color: theme.palette.secondary.main,
    maxWidth: 350,
    [theme.breakpoints.down(768)]: {
      maxWidth: '100%',
    },
  },
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column',
    },
  },
  autoLoc: {
    width: '100%',
  },
  cartBtn: {
    marginLeft: 40,
    marginRight: 20,
    minWidth: 310,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
    },
  },
  wrapSearch: {
    fontWeight: 700,
    width: '100%',
    display: 'flex',
    flex: 1,
    maxWidth: 'calc(100% - 326px - 30px)',
    minWidth: 'calc(326px)',
    [theme.breakpoints.down(768)]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
}));

const SubHeader = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const localLocation = sessionStorage.getItem('location');
  const parseLocalLocation: any = localLocation ? JSON.parse(localLocation) : null;
  const [selected, setSelected] = useState({
    id: undefined,
    address: '',
    lng: 0,
    lat: 0,
    city: '',
    country: '',
    house: '',
    province: '',
    street: '',
    working_times: undefined,
  });

  const {
    subheaderContainerCssClasses,
    subheaderCssClasses,
    subheaderMobileToggle,
  } = useSelector((store: IAppState) => ({
    subheaderMobileToggle: objectPath.get(
      store.builder.layoutConfig,
      'subheader.mobile-toggle'
    ),
    subheaderCssClasses: builder.selectors.getClasses(store, {
      path: 'subheader',
      toString: true,
    }),
    subheaderContainerCssClasses: builder.selectors.getClasses(store, {
      path: 'subheader_container',
      toString: true,
    }),
  }));

  const { loadingLocations, locations, byCoordsLoading } = useSelector((store: IAppState) => ({
    locations: store.yaLocations.yaLocations,
    loadingLocations: store.yaLocations.loading,
    byCoordsLoading: store.yaLocations.getLoacationByCoordsLoading,
  }));

  const { page, perPage } = useSelector((store: IAppState) => ({
    page: store.productsCatalog.page,
    perPage: store.productsCatalog.per_page,
  }));

  const { me } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));

  // useEffect(() => {
  //   const location = localStorage.getItem('location');
  //   if (location) {
  //     try {
  //       const parseLocalLocation = JSON.parse(location);
  //       setSelected(parseLocalLocation);
  //     } catch (error) {
  //     }
  //   }
  // }, []);
  useEffect(() => {
    me?.location &&
      localStorage.setItem(
        'location',
        JSON.stringify({
          address: me.location.address,
          lat: me.location.lat,
          lng: me.location.lng,
        })
      );

    parseLocalLocation
      ? setSelected(parseLocalLocation)
      : me?.location &&
        // @ts-ignore
        setSelected({
          address: me.location.address,
          lat: me.location.lat,
          lng: me.location.lng,
        });
  }, [me]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <LayoutContextConsumer>
      {/*{({ subheader: { title, breadcrumb } }) => (*/}

      {({
        subheader: {
          title,
          breadcrumb,
          description,
          show,
          onPress,
          btnTitle,
          back,
          btnAddCart,
          textBtnAddCart,
        },
      }: any) =>
        show && (
          <div
            id='kt_subheader'
            className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
          >
            <div className={`kt-container ${subheaderContainerCssClasses} `}>
              <div className='kt-subheader__main '>
                <>
                  {subheaderMobileToggle && (
                    <button
                      className='kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left'
                      id='kt_subheader_mobile_toggle'
                    >
                      <span />
                    </button>
                  )}

                  <div className='kt-subheader__wrap-left'>
                    {back && (
                      <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                        <ArrowBackIosIcon />
                      </div>
                    )}
                    {breadcrumb && (
                      <BreadCrumbs items={breadcrumb} hideBreadcrumbIcon={false} />
                    )}
                    {title && description && (
                      <span className='kt-subheader__separator kt-subheader__separator--v' />
                    )}
                    {title && (
                      <h3 className='kt-subheader__title' style={{ fontWeight: 600, fontFamily: 'Onest', }}>
                        {title}
                      </h3>
                    )}

                    {btnTitle && (
                      <h3
                        onClick={onPress}
                        className='kt-subheader__title'
                        style={{ fontWeight: 600, color: '#7A7BF5', cursor: 'pointer' }}
                      >
                        {btnTitle}
                      </h3>
                    )}

                    {textBtnAddCart && (
                      <h3
                        onClick={btnAddCart}
                        className='kt-subheader__title'
                        style={{ fontWeight: 600, color: '#7A7BF5', cursor: 'pointer' }}
                      >
                        {textBtnAddCart}
                      </h3>
                    )}

                    {location.pathname === '/products/catalog' && (
                      <>
                        <ButtonAddProduct />
                      </>
                    )}
                  </div>
                  <div className={classes.container}>
                    {location.pathname === '/products/catalog' && (
                      <div
                        onClick={handleClick}
                        className={`kt-user-card__avatar ${classes.cartBtn}`}
                      >
                        <div
                          className={`kt-menu__link-text  ${classes.cartText}`}
                          style={{ fontWeight: 700, flex: 1 }}
                        >
                          <AutocompleteLocations
                            // style={{ fontWeight: 700, flex: 1 }}
                            options={locations || []}
                            loading={loadingLocations || byCoordsLoading}
                            inputValue={selected}
                            editable
                            label={intl.formatMessage({
                              id: 'STORE.INPUT.LOCATION',
                            })}
                            inputClassName={classes.autoLoc}
                            fetchLocations={data =>
                              dispatch(locationActions.fetchRequest(data))
                            }
                            clearLocations={() => dispatch(locationActions.clear())}
                            setSelectedLocation={location => {
                              setSelected(location || null);
                              localStorage.setItem('location', JSON.stringify(location));
                              sessionStorage.setItem('location', JSON.stringify(location));
                              dispatch(
                                productCatalogActions.fetchRequest({
                                  page,
                                  perPage,
                                  filter: { parameters: [], price_from: '', price_to: '' },
                                  categoryId: 0,
                                  locale: location,
                                })
                              );
                            }}
                            disable={false}
                            inputError={false}
                            inputHelperText={false}
                          />
                        </div>
                        {/* <Popover */}
                        {/*   id={id} */}
                        {/*   open={open} */}
                        {/*   anchorEl={anchorEl} */}
                        {/*   onClose={handleClose} */}
                        {/*   disableScrollLock={true} */}
                        {/*   anchorOrigin={{ */}
                        {/*     vertical: 'bottom', */}
                        {/*     horizontal: 'left', */}
                        {/*   }} */}
                        {/*   transformOrigin={{ */}
                        {/*     vertical: 'top', */}
                        {/*     horizontal: 'center', */}
                        {/*   }} */}
                        {/*   style={{ */}
                        {/*     top: '20px', */}
                        {/*   }} */}
                        {/* > */}
                        {/*   <div style={{ */}
                        {/*     maxWidth: 250, */}
                        {/*     minWidth: 250, */}
                        {/*   }}> */}
                        {/*      */}
                        {/*   </div> */}
                        {/* </Popover> */}
                      </div>
                    )}
                    {/* {!me ||
                      (!me?.roles.includes(UserRoles.ROLE_BUYER) &&
                        this.props.location.pathname === '/products/catalog' && (
                          <SearchField isSubHeader={true} />
                        ))} */}
                    {/* {userRoles?.[0] !== 'ROLE_VENDOR' && (
                    <Link to={`/cart`} className="kt-menu__link ">
                      <i className={`kt-menu__link-icon flaticon-cart`} />
                      <span
                        className="kt-menu__link-text"
                        style={{ paddingLeft: 10, fontWeight: 400 }}
                      >
                        <FormattedMessage
                          id="CART.SUBHEADER.TITLE"
                          defaultMessage={'CART.SUBHEADER.TITLE'}
                        />
                      </span>
                    </Link>
                  )} */}
                    {!me ||
                      (!me?.roles.includes(UserRoles.ROLE_BUYER) &&
                        location.pathname === '/main/catalog' && (
                          <div className={classes.wrapSearch}>
                            <SearchField isSubHeader={true} />
                          </div>
                        ))}
                  </div>
                  {description && <span className='kt-subheader__desc'>{description}</span>}
                </>
              </div>
            </div>
          </div>
        )
      }
    </LayoutContextConsumer>
  );
};

export default SubHeader;
