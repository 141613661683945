import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { connectDemo } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useRequestConnectDemo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<number | null>(null);
  const fm = useFormatMessage();

  const fetchConnectDemo = useCallback(async (project_id: number) => {
    setLoading(true);
    connectDemo(project_id)
      .then(res => {
        setSuccess(res.data.data.id);
        enqueueSnackbar(fm('REQUEST.CONNECT.SUCCESS'), { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setSuccess(null);
        }, 100);
      });
  }, []);

  return { fetchConnectDemo, loading, success };
};
