import React, { useCallback, useLayoutEffect } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { toAbsoluteUrl } from '../../../../../../_base';
import { useStylesModalTariff } from '../../hooks/useStyles';
import { IUser } from '../../../../../interfaces/user';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { useEditCompanyTariff } from '../../hooks/useEditCompanyTariff';
import { ITariff } from '../../../../../interfaces/tariff';
import { IInvoice, IProject } from '../../../../../interfaces/projects';
import TariffTable from '../TariffTable';
import { getDateStop } from '../../utils/getStopDate';
import { useCreateInvoice } from '../../../projects/hooks/useCreateInvoice';
import { useGetTariffName } from '../../../projects/hooks/useGetTariffName';
// import { IUser } from '../../../../interfaces/user';

interface IProps {
  open: boolean;
  handleClose: (update?: boolean) => void;
  showBuyModal?: (id: number, days: number) => void;
  project?: IProject | null;
  successFunc?: () => void;
  tariffs: ITariff[];
  me: IUser;
  successInvoice?: (item: IInvoice) => void;
}

const TariffModal: React.FC<IProps> = ({
  open,
  handleClose,
  successFunc,
  showBuyModal,
  project,
  tariffs,
  me,
  successInvoice,
}) => {
  const classes = useStylesModalTariff();
  const fm = useFormatMessage();
  const intl = useIntl();
  const isVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const { editCompanyTariffFetch, successEditCompany } = useEditCompanyTariff();
  const getTarriffName = useGetTariffName();

  const { fetchInvoiceProject } = useCreateInvoice(successInvoice);

  const payTariff = useCallback(
    (tariff: ITariff, days: number) => {
      if (project) {
        if (me?.is_admin) {
          editCompanyTariffFetch(project.id, days, tariff.id);
        } else {
          fetchInvoiceProject(project.id, tariff.id, days);
          handleClose(false);
        }
      }
    },
    [project]
  );

  useLayoutEffect(() => {
    successEditCompany && successFunc && successFunc();
  }, [successEditCompany]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        // handleClose(updateTariff);
        handleClose(false);
      }}
      style={{ margin: 0, padding: 0 }}
      fullWidth
      maxWidth='md'
    >
      <div className={classes.container}>
        <div className={classes.closeWrapper}>
          <div>
            {project?.current_tariff?.tariff && (
              <Typography component='p' style={{ fontSize: 14, fontWeight: 'bold' }}>{`${fm(
                'CURRENT.TARIFF.PROJECT'
              )}: ${
                project?.current_tariff?.tariff
                  ? `${getTarriffName(project.current_tariff?.tariff.id)} (${
                      project.current_tariff.tariff.users_num
                    } ${fm('USERS').toLocaleLowerCase()})`
                  : ''
              }`}</Typography>
            )}
            {project && project?.current_tariff?.tariff?.id !== 7 && (
              <Typography component='p' style={{ fontSize: 14, fontWeight: 'bold' }}>{`${fm(
                'USER.TABLE.STOPDATETARIFF'
              )}: ${
                project?.current_tariff?.stop_datetime
                  ? getDateStop(project?.current_tariff, intl.locale)
                  : ''
              }`}</Typography>
            )}
          </div>
          <img
            alt='close'
            height={20}
            width={20}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // handleClose(updateTariff)
              handleClose(false);
            }}
            src={toAbsoluteUrl('/media/icons/x.svg')}
          />
        </div>
        {/* <div className={classes.header}>
          <p className={classes.headerTitle}>{fm('TARIFF.SELECT_TARIFF_TITLE')}</p>
          <p className={classes.headerSubTitle}>{fm('TARIFF.SELECT_TARIFF_SUBTITLE')}</p>
        </div> */}
        {tariffs.length > 0 && (
          <TariffTable
            editable={false}
            loading={false}
            success={false}
            editTariff={() => {}}
            tariffs={tariffs}
            canPay
            payTariff={payTariff}
            isVendor={isVendor || undefined}
            project={project}
            // disablePay={
            //   me.current_user_tariff
            //     ? [4, 2].includes(me?.current_user_tariff.tariff.id)
            //     : false
            // }
            // canPay={
            //   me.roles.includes(UserRoles.ROLE_BUYER) ||
            //   me.roles.includes(UserRoles.ROLE_VENDOR)
            // }
          />
        )}
      </div>
    </Dialog>
  );
};

export default TariffModal;
