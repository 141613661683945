import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { IInvoice } from '../../../../interfaces/projects';
import { getInvoices } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useGetInvoices = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const fm = useFormatMessage();

  const fetchInvoices = useCallback(
    async (
      page: number,
      perPage: number,
      status?: 'not_paid' | 'paid' | 'archived' | 'all' | 'in_progress'
    ) => {
      setLoading(true);
      getInvoices(page, perPage, status)
        .then(res => {
          setTotal(res.data.total);
          setPage(res.data.page);
          setPerPage(res.data.per_page);
          setInvoices(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  return { fetchInvoices, loading, page, perPage, total, invoices };
};
