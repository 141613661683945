import React, { useEffect, useState } from 'react';
import { makeStyles, TextField, CircularProgress } from '@material-ui/core';

import NumberFormatTariffCustom from '../../../../../../components/formComponents/NumberFormatTariffCustom';

const useStyles = makeStyles(theme => {
  return {
    textField: {
      margin: 0,
      width: 100,
      // height: 53,
      textAlign: 'center',
    },
    containerField: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 26,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circle1: {
      width: 20,
      height: 20,
      minWidth: 20,
      minHeight: 20,
      borderRadius: 10,
      border: `2px solid #7A7BF5`,
      backgroundColor: '#ffff',
      cursor: 'pointer',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circle2: {
      width: 10,
      height: 10,
      minWidth: 10,
      minHeight: 10,
      borderRadius: 10,
      backgroundColor: theme.palette.secondary.main,
    },
  };
});

interface Props {
  row: string;
  submit: (newValue: string | number | null) => void;
  loading: boolean;
  success: boolean;
  editable: boolean;
  currency: string;
  isCheck?: boolean;
  setCheck?: () => void;
  color?: string;
}

const EditableRow = ({
  row,
  submit,
  loading,
  success,
  editable,
  currency,
  isCheck,
  setCheck,
  color,
}: Props) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(row);

  useEffect(() => {
    setEdit(false);
  }, [success]);

  if (loading) return <CircularProgress color='inherit' size={30} />;

  return (
    <div className={classes.containerField}>
      {edit ? (
        <TextField
          autoFocus
          margin='none'
          value={value}
          onChange={e => setValue(e.target.value)}
          className={classes.textField}
          onBlur={() => {
            if (value !== row) {
              submit(value);
            } else {
              setEdit(false);
            }
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              if (value !== row) {
                submit(value);
              } else {
                setEdit(false);
              }
            }
          }}
          variant='filled'
          autoComplete='off'
          InputProps={{
            inputComponent: NumberFormatTariffCustom as any,
            style: {
              color,
              borderColor: color,
              border: '1px solid #E1E5EB',
              fontSize: 12,
              fontWeight: 600,
            },
          }}
        />
      ) : (
        <div
          className={classes.container}
          style={{
            cursor: !editable && setCheck ? 'pointer' : 'default',
          }}
          onClick={() => {
            if (editable) {
              setEdit(true);
            } else {
              setCheck && setCheck();
            }
          }}
        >
          {setCheck && !editable && (
            <div onClick={setCheck} className={classes.circle1}>
              {isCheck && <div className={classes.circle2} />}
            </div>
          )}
          {row} {currency}
        </div>
      )}
    </div>
  );
};

export default EditableRow;
