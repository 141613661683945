import { createStyles, makeStyles } from '@material-ui/core';

export const useStylesProjectsPage = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    filterColTop: {
      width: '100%',
      marginRight: 30,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        minWidth: 326,
        maxWidth: 326,
      },
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    tableContainer: {
      flex: 1,
      margin: 5,
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    card: {
      width: '97%',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20,
      },
      boxShadow: '2px 2px 3px -1px rgba(0, 0, 0, 0.25) !important',
    },
    itemContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
    title_container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      color: '#39364F',
      fontSize: 34,
      height: 45,
      fontWeight: 'bold',
    },
    titleName: {
      color: '#39364F',
      fontSize: 28,
      height: 36,
      fontWeight: 600,
    },
    subTitle: {
      color: '#39364F',
      fontSize: 20,
      height: 25,
      fontWeight: 600,
    },
    titleStatus: {
      color: '#39364F',
      fontSize: 15,
      height: 15,
      fontWeight: 600,
    },
    titleTariff: {
      color: '#39364F',
      fontSize: 15,
      fontWeight: 600,
    },
    statusContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 7,
    },
    statusCheck: {
      width: 15,
      height: 15,
      borderRadius: 15,
      background: '#d7141a',
      marginRight: 5,
      marginTop: 7,
    },
    tariffContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '40%',
    },
    tariffContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    tariff: {
      color: '#39364F',
      fontSize: 15,
      height: 25,
      fontWeight: 'normal',
      marginLeft: 5,
    },
    getBtn: {
      borderRadius: 18,
      paddingLeft: 45,
      paddingRight: 45,
      paddingTop: 5,
      paddingBottom: 5,
      height: 30,
      marginTop: 7,
      // background: 'linear-gradient(to bottom, #7d1f9d, #470d63)',
      fontSize: 15,
      fontFamily: 'Onest',
      fontWeight: 600,
    },
    iconContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 130,
    },
    inactive: {
      border: '2px #E793A9 solid',
      background: '#F52F64',
      color: '#F52F64',
    },
    active: {
      border: '2px #9FE8AB solid',
      background: '#44D65E',
      color: '#44D65E',
    },

    not_paid: {
      border: '2px #E793A9 solid',
      background: '#F52F64',
      color: '#F52F64',
    },
    paid: {
      border: '2px #9FE8AB solid',
      background: '#44D65E',
      color: '#44D65E',
    },

    in_progress: {
      border: '2px #BABBF7 solid',
      background: '#7A7BF5',
      color: '#7A7BF5',
    },

    archived: {
      border: '2px #BABBF7 solid',
      background: '#7A7BF5',
      color: '#7A7BF5',
      opacity: '0.5',
    },

    inactiveTitle: {
      color: '#F52F64',
    },
    activeTitle: {
      color: '#44D65E',
    },

    not_paidTitle: {
      color: '#F52F64',
    },
    paidTitle: {
      color: '#44D65E',
    },

    in_progressTitle: {
      color: '#7A7BF5',
    },

    archivedTitle: {
      color: '#7A7BF5',
      opacity: '0.5',
    },

    containerPaidStatus: {
      marginLeft: 25,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    emptyContainer: {
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: 20,
      paddingLeft: 20,
      paddingTop: 20,
    },
    title_section: {
      width: '43%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
    gradientBtn: {
      height: 40,
      // background: 'linear-gradient(to bottom, #7d1f9d, #470d63)',
      fontSize: '12px',
      fontFamily: 'Onest',
      fontWeight: 700,
    },
    editBtn: {
      fontFamily: 'Onest',
      fontSize: 15,
      fontWeight: 600,
      color: '#7A7BF5',
    },
    icon: {
      background: 'white',
    },
  })
);

export const useStylesEditProject = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
  companySearch: {
    display: 'flex',
    width: '100%',
    maxWidth: 800,
    paddingBottom: 0,
  },
  allCompaniesBtn: {
    marginTop: 8,
    marginLeft: 10,
    height: 52,
  },
  btnDomain: {
    marginLeft: theme.spacing(2),
    height: 52,
    marginTop: theme.spacing(1),
  },
  domainContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
