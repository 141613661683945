import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  TextField,
  Button,
  DialogProps,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { useIntl } from 'react-intl';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { actions as tariffActions } from '../../../store/ducks/tariff.duck';
import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import Preloader from '../../../components/other/Preloader/Preloader';

import homeStyles from '../../../constants/homeStyles';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { IAppState } from '../../../store/rootDuck';
import { useGetProject } from './hooks/useGetProject';
import { IInvoice, IProject } from '../../../interfaces/projects';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { Modal } from '../../../components/other/Modals';
import { useGetUsers } from './hooks/useGetVendors';
import UsersTable from '../../../components/tableComponents/Table/UsersTable';
import { UserListType } from '../users/constants';
import { useAddProject } from './hooks/useAddProject';
import { useEditProject } from './hooks/useEditProject';
import { useStylesEditProject } from './hooks/useStyles';
import { getFio } from '../users/users/utils';
import { getDateStop } from '../tariffs/utils/getStopDate';
import { TariffTable } from '../tariffs/components';
import { ITariff } from '../../../interfaces/tariff';
import { useEditCompanyTariff } from '../tariffs/hooks/useEditCompanyTariff';
import { useCreateInvoice } from './hooks/useCreateInvoice';
import InvoiceModal from '../invoices/components/InvoiceModal';
import { useGetInvoice } from '../settings/invoice/hooks/useGetInvoice';
import { useGetTariffName } from './hooks/useGetTariffName';
import { usePaidInvoice } from '../invoices/hooks/usePaidInvoice';

const getInitialValues = (project: IProject | null) => ({
  name: project?.name || '',
  domain: project?.domain || '',
  currentTariff: project?.current_tariff || null,
  stopDatetime: project?.current_tariff?.stop_datetime || '',
  status: project?.status || '',
  author: project?.author || null,
  api: project?.api || '',
});

const ProjectPage: React.FC = () => {
  const classes = useStylesEditProject();
  const homeClasses = homeStyles();
  const { projectId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const intl = useIntl();
  const getTarriffName = useGetTariffName();
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const { me } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));
  const [tabValue, setTabValue] = useState(projectId ? 1 : 0);
  const [invoiceModal, setInvoiceModal] = useState<IInvoice | null>(null);
  const { fetchGetInvoice, invoice } = useGetInvoice();
  const { fetchPaidInvoice, loading: loadingPaid, success: successPaid } = usePaidInvoice();
  const { fetchGetProject, loading, project } = useGetProject();
  const { fetchAddProject, loading: loadingAdd, success, error, setError } = useAddProject();
  const {
    fetchEditProject,
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
    setError: setErrorEdit,
  } = useEditProject();
  const { fetchUsers, loading: loadingUsers, page, perPage, total, users } = useGetUsers();
  const {
    fetchInvoiceProject,
    loading: loadingInvoice,
    success: successInvoice,
  } = useCreateInvoice(item => {
    setInvoiceModal(item);
  });

  const { tariffs } = useSelector((store: IAppState) => ({
    tariffs: store.tariff.tariffs,
  }));

  const {
    editCompanyTariffFetch,
    loadingEditCompanyTariff,
    successEditCompany,
  } = useEditCompanyTariff();

  const isVendor = useDefineUserRole(me, 'ROLE_VENDOR');

  const {
    touched,
    errors,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: getInitialValues(project),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
      author: Yup.object()
        .nullable()
        .required(fm('AUTH.VALIDATION.REQUIRED_FIELD'))
        .shape({
          id: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
        }),
    }),
    onSubmit: submitValues => {
      if (projectId) {
        fetchEditProject(+projectId, {
          name: submitValues.name,
          authorId: submitValues.author?.id,
          status: submitValues.status || 'inactive',
          domain: submitValues.domain,
          api: submitValues.api,
          active: submitValues.status === 'active',
        });
      } else {
        fetchAddProject({
          name: submitValues.name,
          authorId: submitValues.author?.id,
        });
      }
    },
  });

  setLayoutSubheader({
    title: fm(projectId ? 'PROJECT.EDIT.TITLE' : 'PROJECT.ADD.TITLE'),
    breadcrumb: [],
  });

  setLayoutFooter({ show: true });

  const handleOpenUsersDialog = useCallback(() => {
    fetchUsers(1, 20);
    setOpenUsersDialog(true);
  }, []);

  const payTariff = useCallback(
    (tariff: ITariff, days: number) => {
      if (project) {
        if (me?.is_admin) {
          editCompanyTariffFetch(project.id, days, tariff.id);
        } else {
          fetchInvoiceProject(project.id, tariff.id, days);
          setCloseDialog(true);
        }
      }
    },
    [project, me]
  );

  useLayoutEffect(() => {
    fetchGetInvoice();
    dispatch(tariffActions.fetchRequest());
  }, []);

  useLayoutEffect(() => {
    success && navigate(`/projects/edit/${success}`, { replace: true });
  }, [success]);

  // useLayoutEffect(() => {
  //   if (success || successEdit || successEditCompany) {
  //     // navigate(-1);
  //     projectId && fetchGetProject(+projectId);
  //   }
  // }, [success, successEdit, successEditCompany]);

  useLayoutEffect(() => {
    if (successPaid) {
      projectId && fetchGetProject(+projectId);
    }
    if (successEdit || successEditCompany) {
      navigate('/projects/list');
    }
  }, [successEdit, successEditCompany, successPaid]);

  useLayoutEffect(() => {
    projectId && fetchGetProject(+projectId);
  }, [projectId]);

  useLayoutEffect(() => {
    if (state?.user && !projectId) {
      setFieldValue('author', state?.user);
    }
  }, [state]);

  useLayoutEffect(() => {
    if (isVendor && !projectId) {
      setFieldValue('author', me);
    }
  }, [isVendor]);

  if (me && !me.is_admin && !isVendor) return <></>;

  if (loading || !me) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <Card className={homeClasses.container}>
            <>
              {projectId && (
                <Tabs
                  value={tabValue}
                  onChange={(_: React.ChangeEvent<{}>, newValue: number) =>
                    setTabValue(newValue)
                  }
                  indicatorColor='primary'
                  textColor='primary'
                >
                  <Tab label={fm('PRODUCT.TABS.COMMON')} />
                  <Tab label={fm('USER.TABLE.TARIFF')} />
                </Tabs>
              )}
              <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
                <form className={homeClasses.form} onSubmit={handleSubmit} autoComplete='off'>
                  <Tooltip
                    title={
                      !!projectId && project?.status === 'active'
                        ? fm('PROJECT.DISABLED.TITLE')
                        : ''
                    }
                  >
                    <TextField
                      type='text'
                      label={fm('BODY.TABLE.NAME')}
                      margin='normal'
                      name='name'
                      value={values.name}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('name', e.target.value);
                        setError(null);
                        setErrorEdit(null);
                      }}
                      helperText={
                        touched.name && (errors.name || error?.error || errorEdit?.error)
                      }
                      error={Boolean(
                        touched.name && (errors.name || error?.error || errorEdit?.error)
                      )}
                      disabled={!!projectId && project?.status === 'active'}
                    />
                  </Tooltip>

                  {!isVendor && (
                    <div className={classes.companySearch}>
                      <TextField
                        label={fm('USER.ROLES.VENDOR')}
                        margin='normal'
                        placeholder=''
                        value={values.author ? getFio(values.author, true) : ''}
                        variant='outlined'
                        helperText={touched.author && errors.author}
                        error={Boolean(touched.author && errors.author)}
                        InputProps={{
                          readOnly: true,
                          endAdornment: values.author ? (
                            <IconButton onClick={() => setFieldValue('author', null)}>
                              <DeleteIcon fontSize='small' color='error' />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleOpenUsersDialog}>
                              <SearchIcon color='primary' fontSize='small' />
                            </IconButton>
                          ),
                        }}
                      />
                      <Button
                        style={{ marginTop: 16 }}
                        variant='outlined'
                        color='primary'
                        onClick={() => handleOpenUsersDialog()}
                        className={classes.allCompaniesBtn}
                      >
                        {fm('MENU.USERS')}
                      </Button>
                    </div>
                  )}

                  {projectId && (
                    <>
                      <div className={classes.domainContent}>
                        <Tooltip
                          title={
                            !!projectId && project?.status === 'active'
                              ? fm('PROJECT.DISABLED.TITLE')
                              : ''
                          }
                        >
                          <TextField
                            type='text'
                            label={fm('DOMAIN')}
                            margin='normal'
                            name='domain'
                            value={values.domain}
                            onChange={handleChange}
                            variant='outlined'
                            disabled={
                              (!!projectId && project?.status === 'active') || !me.is_admin
                            }
                          />
                        </Tooltip>
                        {(me.is_admin || (values.status === 'active' && values.domain)) && (
                          <Button
                            component='a'
                            href={values.domain ? `https://${values.domain}` : undefined}
                            target='_blank'
                            rel='noreferrer'
                            className={classes.btnDomain}
                            variant='outlined'
                            color='primary'
                          >
                            {fm('PROJECT.DOMAIN.BTN')}
                          </Button>
                        )}
                      </div>

                      {me.is_admin && (
                        <Tooltip
                          title={
                            !!projectId && project?.status === 'active'
                              ? fm('PROJECT.DISABLED.TITLE')
                              : ''
                          }
                        >
                          <TextField
                            type='text'
                            label='API'
                            margin='normal'
                            name='api'
                            value={values.api}
                            onChange={handleChange}
                            variant='outlined'
                            disabled={!!projectId && project?.status === 'active'}
                          />
                        </Tooltip>
                      )}

                      {!!projectId && project?.status === 'active' && (
                        <Typography
                          component='h2'
                          color='textSecondary'
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          {fm('PROJECT.DISABLED.TITLE')}
                        </Typography>
                      )}

                      <FormControl style={{ margin: '16px 0', width: '100%' }}>
                        <InputLabel>{fm('PRODUCT.EDIT.STATUS')}</InputLabel>
                        <Select
                          value={values.status}
                          label={fm('PRODUCT.EDIT.STATUS')}
                          disabled={!!isVendor && !!projectId}
                          onChange={e => setFieldValue('status', e.target.value)}
                        >
                          <MenuItem value='inactive'>
                            {fm('PARTICIPATION.TABLE.ACTIVE_FALSE')}
                          </MenuItem>
                          <MenuItem value='active'>
                            {fm('PARTICIPATION.TABLE.ACTIVE_TRUE')}
                          </MenuItem>
                          <MenuItem value='in_progress'>{fm('STATUS.SETUP')}</MenuItem>
                        </Select>
                      </FormControl>

                      {project?.invoice && (
                        <FormControl style={{ margin: '16px 0', width: '100%' }}>
                          <InputLabel>{fm('ORDER.PAYMENT.STATUS')}</InputLabel>
                          <Select
                            value={project.pay_status}
                            label={fm('ORDER.PAYMENT.STATUS')}
                            disabled={!!isVendor && !!projectId}
                            onChange={e => {
                              if (!project.invoice?.id) return;
                              if (e.target.value === 'in_progress') return;
                              fetchPaidInvoice(
                                project.invoice?.id,
                                e.target.value as 'paid' | 'not_paid' | 'archived'
                              );
                            }}
                          >
                            <MenuItem value='paid'>{fm('ORDER.PAYMENT.STATUS.PAID')}</MenuItem>
                            <MenuItem value='not_paid'>
                              {fm('ORDER.PAYMENT.STATUS.NOT_PAID')}
                            </MenuItem>
                            <MenuItem value='archived'>{fm('ARCHIVE')}</MenuItem>
                            <MenuItem hidden value='in_progress'>
                              {fm('ORDER.TYPE.PROCCESS')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}

                  <div className={classes.actions}>
                    <Button
                      onClick={() => navigate(-1)}
                      className={classes.buttons}
                      variant='outlined'
                      color='primary'
                    >
                      {fm('COMMON.BUTTON.BACK')}
                    </Button>

                    <ButtonWithLoader
                      disabled={
                        loadingEdit || loadingAdd || loadingEditCompanyTariff || loadingPaid
                      }
                      loading={
                        loadingAdd || loadingEdit || loadingEditCompanyTariff || loadingPaid
                      }
                    >
                      {fm(projectId ? 'USER.BUTTON.EDIT' : 'USER.BUTTON.ADD')}
                    </ButtonWithLoader>
                  </div>
                </form>
              </div>

              <div style={{ display: tabValue === 1 ? 'contents' : 'none' }}>
                <form className={homeClasses.form} autoComplete='off'>
                  <Typography
                    component='p'
                    style={{ fontSize: 14, fontWeight: 'bold' }}
                  >{`${fm('CURRENT.TARIFF.PROJECT')}: ${
                    project?.current_tariff?.tariff
                      ? `${getTarriffName(project.current_tariff?.tariff.id)} (${
                          project.current_tariff.tariff.users_num
                        } ${fm('USERS').toLocaleLowerCase()})`
                      : ''
                  }`}</Typography>
                  {project?.current_tariff?.tariff?.id !== 7 && (
                    <Typography
                      component='p'
                      style={{ fontSize: 14, fontWeight: 'bold' }}
                    >{`${fm('USER.TABLE.STOPDATETARIFF')}: ${
                      project?.current_tariff?.stop_datetime
                        ? getDateStop(values.currentTariff, intl.locale)
                        : ''
                    }`}</Typography>
                  )}
                  {tariffs.length > 0 && (
                    <TariffTable
                      editable={false}
                      loading={false}
                      success={false}
                      editTariff={() => {}}
                      tariffs={tariffs}
                      canPay
                      payTariff={payTariff}
                      closeDialog={closeDialog}
                      setCloseDialog={setCloseDialog}
                      isVendor={isVendor || undefined}
                      project={project}
                      isDemo={
                        project?.name.toLowerCase() === 'demo' ||
                        (!!isVendor && project?.pay_status === 'in_progress')
                      }
                    />
                  )}
                </form>
              </div>
            </>
          </Card>
        </Col>
      </Row>

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openUsersDialog}
        onClose={() => setOpenUsersDialog(false)}
        title={fm('MENU.USERS')}
        loading={loadingUsers}
        content={
          <UsersTable
            loading={loadingUsers}
            paginationData={{ page, perPage, total }}
            onEdit={userId => navigate(`/users/edit/${UserListType.VENDOR}/${userId}`)}
            onConnect={(_id, _login, item) => {
              setFieldValue('author', item);
              setOpenUsersDialog(false);
            }}
            onConnectOff={() => {
              setFieldValue('author', null);
            }}
            connectedUsers={values.author ? [values.author.id] : undefined}
            users={users}
            fetch={({ page, perPage }) => fetchUsers(page, perPage)}
          />
        }
        actions={[
          {
            title: fm('COMMON.BUTTON.CLOSE'),
            onClick: () => setOpenUsersDialog(false),
          },
        ]}
      />
      <InvoiceModal
        invoice={invoiceModal}
        handleClose={() => {
          setInvoiceModal(null);
          navigate('/invoices/list');
        }}
        open={!!invoiceModal}
        settingInvoice={invoice}
      />
    </>
  );
};

export default ProjectPage;
