import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as builder from '../../ducks/builder';
import TermDialog from '../../../app/components/other/Dialog/TermDialog';
import { LayoutContextConsumer } from '../LayoutContext';
import { useFormatMessage } from '../../../app/hooks';
import { IAppState } from '../../../app/store/rootDuck';
import { toAbsoluteUrl } from '../../utils/utils';

type TProps = {
  collections: any;
};

const Footer: React.FC<TProps> = ({ collections }) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogContentType, setDialogContentType] = React.useState('');

  const handleDialogOpen = useCallback((contentType: string) => {
    setDialogContentType(contentType);
    setOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const clickCollection = useCallback((item: any) => {
    const params: any = {};
    const paramsArray: any = [];
    item.parameters.forEach((param: any) => {
      if (param.filter_type) {
        params[param.parameter] = { values: param.values };
        paramsArray.push({
          parameter: param.parameter,
          values: param.values,
        });
      }
    });
    const productsFilter = {
      price_to:
        item.max_price && Number.isInteger(item.max_price) ? item.max_price.toString() : '',
      price_from:
        item.min_price && Number.isInteger(item.min_price) ? item.min_price.toString() : '',
      parameters: params,
    };

    navigate('/products/catalog', {
      state: {
        filter: productsFilter,
        productTypeId: item.product_type_id,
        paramsArray,
      },
    });
  }, []);

  const { footerClasses, footerContainerClasses } = useSelector((store: IAppState) => ({
    footerClasses: builder.selectors.getClasses(store, {
      path: 'footer',
      toString: true,
    }),
    footerContainerClasses: builder.selectors.getClasses(store, {
      path: 'footer_container',
      toString: true,
    }),
  }));

  return (
    <LayoutContextConsumer>
      {({ footer: { show } }: any) =>
        show && (
          <div
            className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
            id='kt_footer'
          >
            <div className={`kt-container ${footerContainerClasses}`}>
              <div className='kt-footer__copyright'>
                <div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                    <img
                      alt='logo'
                      src={toAbsoluteUrl('/media/logos/logosAwes2Pro.png')}
                      height={40}
                    />

                    <div style={{flexDirection: 'column', display: 'flex', marginLeft: 10}}>
                      <span style={{fontSize: 14, fontWeight: 600, fontFamily: 'Onest',color: '#39364F'}}>Project Management</span>
                      <span style={{fontSize: 14, fontWeight: 600, fontFamily: 'Onest',color: '#39364F'}}>Console</span>
                    </div>
                  </div>

                  <a
                    rel='noopener noreferrer'
                    className='kt-link'
                    onClick={() => handleDialogOpen('user_agreement')}
                    style={{color: '#000', fontFamily: 'Onest', fontSize: 14, fontWeight: 400,}}
                  >
                    {fm('AUTH.GENERAL.LEGAL')}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('privacy_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                    style={{color: '#000', fontFamily: 'Onest', fontSize: 14,fontWeight: 400,}}
                  >
                    {fm('AUTH.GENERAL.PRIVACY')}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('user_namunal')}
                    rel='noopener noreferrer'
                    className='kt-link'
                    style={{color: '#000', fontFamily: 'Onest', fontSize: 14,fontWeight: 400,}}
                  >
                    {fm('SUBMENU.NAMUNAL')}
                  </a>
                  <br />
                  <span style={{color: '#000', fontFamily: 'Onest', fontSize: 14,fontWeight: 400,}}>
                    {fm('CREATOR')}
                  </span>&nbsp;
                  <a
                    href='https://startmobile.us'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='kt-link'
                    style={{color: '#000', fontFamily: 'Onest', fontSize: 14,fontWeight: 400,}}
                  >
                    Start Mobile
                  </a>
                  {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                  {/*<a*/}
                  {/*  onClick={() => handleDialogOpen('shipping_policy')}*/}
                  {/*  rel='noopener noreferrer'*/}
                  {/*  className='kt-link'*/}
                  {/*>*/}
                  {/*  {fm('SUBMENU.SHIPPING')}*/}
                  {/*</a>*/}
                  {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                  {/*<a*/}
                  {/*  onClick={() => handleDialogOpen('refund_policy')}*/}
                  {/*  rel='noopener noreferrer'*/}
                  {/*  className='kt-link'*/}
                  {/*>*/}
                  {/*  {fm('REFUND')}*/}
                  {/*</a>*/}
                  {/*<br />*/}
                  {/*{collections && collections.length !== 0 && (*/}
                  {/*  <>*/}
                  {/*    {fm('COLLECTIONS')}:&nbsp;*/}
                  {/*    {collections.map((item: any, i: any) => (*/}
                  {/*      <React.Fragment key={item.id}>*/}
                  {/*        {i > 0 && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}*/}
                  {/*        <a*/}
                  {/*          onClick={() => clickCollection(item)}*/}
                  {/*          target='_blank'*/}
                  {/*          rel='noopener noreferrer'*/}
                  {/*          className='kt-link'*/}
                  {/*        >*/}
                  {/*          {item.name}*/}
                  {/*        </a>*/}
                  {/*      </React.Fragment>*/}
                  {/*    ))}*/}
                  {/*  </>*/}
                  {/*)}*/}
                </div>

                <div >
                  <span style={{color: '#000', fontFamily: 'Onest', fontSize: 14, fontWeight: 600, paddingRight: 20,}}>
                    {fm('FOOTER.TITLE')}
                  </span>&nbsp;&nbsp;&nbsp;
                  <br />
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 30, marginTop: 10}}>
                    <img
                      onClick={() => {
                        window.open(
                          'https://play.google.com/store/apps/details?id=us.startmobile.awes.pro&pcampaignid=web_share',
                          '_blank'
                        );
                      }}
                      alt='logo'
                      src={toAbsoluteUrl('/media/logos/googlePlay.png')}
                      height={40}
                      style={{marginRight: 15, cursor: 'pointer'}}
                    />
                    <img
                      onClick={() => {
                        window.open(
                          'https://apps.apple.com/us/app/awes-pro/id6670250613',
                          '_blank'
                        );
                      }}
                      alt='logo'
                      src={toAbsoluteUrl('/media/logos/appStore.png')}
                      height={40}
                      style={{cursor: 'pointer'}}
                    />
                    
                  </div>
                </div>
                {/* <div className='kt-footer__menu'></div> */}
                <>
                  <TermDialog
                    isOpen={openDialog}
                    handleClose={handleDialogClose}
                    contentType={dialogContentType}
                  />
                </>
              </div>
            </div>
          </div>
        )
      }
    </LayoutContextConsumer>
  );
};

export default Footer;
