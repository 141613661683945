import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { addProject } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useAddProject = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<number | null>(null);
  const [error, setError] = useState<{ field: string; error: string } | null>(null);
  const fm = useFormatMessage();

  const fetchAddProject = useCallback(async (data: any) => {
    setLoading(true);
    addProject(data)
      .then(res => {
        setSuccess(res.data.data.id);
        enqueueSnackbar(fm('PROJECT.ADDED'), { variant: 'success' });
      })
      .catch(e => {
        if (e?.response?.data?.code === 'duplicate_name') {
          setError({ field: 'name', error: fm('PROJECT.EXIST') });
        } else {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setSuccess(null);
        }, 100);
      });
  }, []);

  return { fetchAddProject, loading, success, error, setError };
};
