import { useCallback } from 'react';
import { useFormatMessage } from '../../../../hooks';

export const useGetTariffName = () => {
  const fm = useFormatMessage();

  const getName = useCallback(
    (id?: number) => {
      switch (id) {
        case 7:
          return fm('START');

        case 8:
          return fm('TARIFF.BASE');

        case 9:
          return fm('MASTER');

        case 10:
          return fm('TARIFF.PRIME');
        default:
          return fm('START');
      }
    },
    [fm]
  );

  return getName;
};
