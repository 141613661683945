import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const TopTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#7A7BF5',
    color: 'white',
    fontSize: 18,
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  [theme.breakpoints.down('xs')]: {
    head: {
      fontSize: 12,
      paddingRight: 0,
      // paddingLeft: theme.spacing(2),
    },
  },
}))(TableCell);

export default TopTableCell;
