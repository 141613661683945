import React from 'react';

interface IProps {
  size?: string;
}

const InvoiceIcon: React.FC<IProps> = ({ size='32' }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
        <defs>
            <filter x="-.2%" y="-2%" width="100.5%" height="105.5%" filterUnits="objectBoundingBox" id="p5a1qs8m2a">
                <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
        </defs>
        <g filter="url(#p5a1qs8m2a)" transform="translate(-1231 -38)" fill="none" fillRule="evenodd">
            <g transform="translate(1232 39)">
                <rect stroke="#7A7BF5" strokeWidth="2" x="1" y="1" width="26" height="26" rx="5"/>
                <g transform="translate(5.6 6.44)" fill="#7A7BF5" fillRule="nonzero">
                    <rect width="16.8" height="1.68" rx=".84"/>
                    <rect y="6.72" width="16.8" height="1.68" rx=".84"/>
                    <rect y="13.44" width="16.8" height="1.68" rx=".84"/>
                </g>
            </g>
        </g>
      </svg>
    </>
  );
};

export default InvoiceIcon;
