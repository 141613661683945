import React, { useEffect, useState } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { IMaskMixin } from 'react-imask';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useFormikCheckInSystem } from '../hooks/useFormikCheckInSystem';
// import SelectField from '../../../components/ui/SelectField';
import { TAuthTabs } from '.';
import RegistrationTermsDialog from '../../../components/ui/Dialog/RegistrationTermsDialog';
import { useAxiosLoginEmail } from '../hooks/useAxiosLoginEmail';

export const IMaskPhoneInput = IMaskMixin(({ ...props }) => {
  return <TextField {...(props as any)} />;
});

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  initEmail?: string;
  initPhone?: string;
  initTab?: number;
  hideGuestBtn?: boolean;
}

export const CheckInSystem: React.FC<IProps> = ({ changeAuthTab, initEmail = '' }) => {
  const intl = useIntl();
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  // const { countryId, setCountryId, countryData } = useHandleCountrySelect(countries);

  // const {
  //   makeCheckInSystemRequest,
  //   loading,
  //   checkInSystemErr,
  //   isFound,
  //   requestedType,
  // } = useAxiosCheckInSystem();

  const { makeLoginEmail, loadingEmail, success } = useAxiosLoginEmail();

  const {
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    isValid,
  } = useFormikCheckInSystem(
    initEmail,
    // initPhone,
    // makeCheckInSystemRequest,
    // tab,
    // countryData,
    makeLoginEmail
  );

  // const openRegistration = useCallback(() => {
  //   const props = {
  //     initTab: tab,
  //     countries,
  //     initPhone: values.phone,
  //     initEmail: values.email,
  //     initCountryId: countryId,
  //   };
  //   changeAuthTab('Registration', props);
  // }, [tab, countries, values.phone, values.email, countryId]);

  // check if user found in system
  // useEffect(() => {
  //   if (isFound === null) return;
  //   if (isFound) {
  //     if (requestedType === 'phone') {
  //       makeRequestReg('phone', { phone: values.phone }, countryData);
  //       changeAuthTab('EnterConfirmCode', { countryData, phone: values.phone });
  //     } else if (requestedType === 'email') {
  //       changeAuthTab('EnterPassword', { email: values.email });
  //     }
  //   } else {
  //     openRegistration();
  //   }
  // }, [isFound, requestedType, changeAuthTab, values.phone, values.email, countryData]);

  useEffect(() => {
    if (success) {
      changeAuthTab('EnterConfirmCodeEmail', { email: values.email });
    }
  }, [success]);

  return (
    <>
      <div className='kt-login__body'>
        <div className='kt-login__form'>
          <div className='kt-login__title'>
            <h3 style={{ fontSize: 34, fontWeight: 'bold', color: '#39364F' }}>
              {intl.formatMessage({ id: 'LOGIN_OR_REGISTER' })}
            </h3>
          </div>

          <form noValidate autoComplete='off' className='kt-form' onSubmit={handleSubmit}>
            {/* <Tabs
              value={tab}
              onChange={(_, newTab) => setTab(newTab)}
              variant='scrollable'
              indicatorColor='primary'
              textColor='primary'
              aria-label='tabs'
            >
              <Tab label={intl.formatMessage({ id: 'BY_EMAIL' })} {...a11yProps(0)} />
              <Tab
                label={intl.formatMessage({ id: 'AUTH.MAIN.TAB.BY_PHONE' })}
                {...a11yProps(1)}
              />
            </Tabs> */}

            {/* <TabPanel value={tab} index={0}> */}
            <div className='form-group'>
              <TextField
                type='email'
                label={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                margin='normal'
                className='kt-width-full'
                name='email'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />
            </div>

            {/* <div className='form-group mb-0' style={{ fontWeight: 400, marginTop: 15 }}>
              <Checkbox
                color='primary'
                name='acceptTerms'
                checked={values.acceptTerms}
                style={{ marginLeft: -10 }}
                onChange={handleChange}
              />
              {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
              <div
                className='kt-link'
                style={{ cursor: 'pointer' }}
                onClick={() => setIsTermsOpen(true)}
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
              </div>
            </div> */}

            {/* <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  margin: '15px 0',
                }}
              >
                <Link
                  to='#'
                  onClick={() => changeAuthTab('ForgotPassword')}
                  className='kt-link kt-login__link-forgot'
                >
                  <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
                </Link>
              </div> */}
            {/* </TabPanel> */}

            {/* <TabPanel value={tab} index={1}>
              <div className='form-group'> */}
            {/* <SelectField
                  label={intl.formatMessage({ id: 'AUTH.INPUT.COUNTRIES' })}
                  data={countries}
                  selectedValue={countryId}
                  setSelectedValue={setCountryId}
                  variant='standard'
                  renderItemText={item => (intl.locale === 'ru' ? item.ru_name : item.en_name)}
                  loading={countriesLoading}
                /> */}

            {/* <CountrySelect
                  countries={countries}
                  countryId={countryId}
                  setCountryId={setCountryId}
                  countriesLoading={countriesLoading}
                  variant='standard'
                />

                {countryData && (
                  <IMaskPhoneInput
                    mask={countryData.mask}
                    value={values.phone}
                    type='tel'
                    label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                    margin='normal'
                    className='kt-width-full'
                    name='phone'
                    autoFocus
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    onBlur={handleBlur}
                    alwaysShowMask
                    onChange={(e: any) => {
                      setFieldValue(
                        'phone',
                        e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                      );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>+{countryData.code}</InputAdornment>
                      ),
                    }}
                  />
                )} */}

            {/* {countryData && (
                  <InputMask
                    mask={countryData.mask}
                    onBlur={handleBlur}
                    value={values.phone}
                    alwaysShowMask
                    onChange={(e: any) => {
                      setFieldValue(
                        'phone',
                        e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                      );
                    }}
                  >
                    {(inputProps: any) => (
                      <TextField
                        {...inputProps}
                        type='tel'
                        label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                        margin='normal'
                        className='kt-width-full'
                        name='phone'
                        autoFocus
                        helperText={touched.phone && errors.phone}
                        error={Boolean(touched.phone && errors.phone)}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              +{countryData.code}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )} */}
            {/* </div>
            </TabPanel> */}

            {/* buttons */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <div className='kt-login__actions'>
                <ButtonWithLoader
                  onPress={handleSubmit}
                  disabled={loadingEmail}
                  loading={loadingEmail}
                >
                  {intl.formatMessage({ id: 'AUTH.MAIN.BUTTON' })}
                </ButtonWithLoader>
              </div>

              {/* {!hideGuestBtn && (
                <div className='kt-login__actions'>
                  <Button onClick={() => navigate('/products/catalog')}>
                    {intl.formatMessage({ id: 'LOGIN_AS_GUEST' })}
                  </Button>
                </div>
              )} */}
            </div>
          </form>
        </div>
      </div>
      <RegistrationTermsDialog
        isOpen={isTermsOpen}
        handleClose={() => setIsTermsOpen(false)}
      />
    </>
  );
};
