import React from 'react';

interface IProps {
  size?: string;
}

const EditIcon: React.FC<IProps> = ({ size }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 29 29">
        <defs>
            <filter x="-.2%" y="-2%" width="100.5%" height="105.5%" filterUnits="objectBoundingBox" id="0vchkcloua">
                <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
        </defs>
        <g filter="url(#0vchkcloua)" transform="translate(-1297 -39)" fill="#7A7BF5" fillRule="nonzero">
            <path d="m1313.622 49.207 1.17 1.171-11.53 11.531h-1.171v-1.17l11.53-11.532m4.583-7.662c-.319 0-.65.128-.891.37l-2.33 2.329 4.773 4.772 2.33-2.329a1.267 1.267 0 0 0 0-1.794l-2.979-2.978a1.25 1.25 0 0 0-.903-.37zm-4.582 4.06-14.077 14.077v4.773h4.773l14.077-14.077-4.773-4.773z"/>
        </g>
    </svg>
    </>
  );
};

export default EditIcon;
