import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { editProject } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useEditProject = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<{ field: string; error: string } | null>(null);
  const fm = useFormatMessage();

  const fetchEditProject = useCallback(async (id: number, data: any) => {
    setLoading(true);
    editProject(id, data)
      .then(() => {
        enqueueSnackbar(fm('PROJECT.EDITED'), { variant: 'success' });
        setSuccess(true);
      })
      .catch(e => {
        if (e?.response?.data?.code === 'duplicate_name') {
          setError({ field: 'name', error: fm('PROJECT.EXIST') });
        } else {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { fetchEditProject, loading, success, error, setError };
};
