import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { payInvoice } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const usePaidInvoice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchPaidInvoice = useCallback(
    async (id: number, status: 'not_paid' | 'paid' | 'archived') => {
      setLoading(true);
      payInvoice(id, status)
        .then(() => {
          setSuccess(true);
          enqueueSnackbar(`${fm('STATUS.CHANGED')}`, { variant: 'success' });
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setSuccess(false);
          }, 100);
        });
    },
    []
  );

  return { fetchPaidInvoice, loading, success };
};
