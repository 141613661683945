import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Button,
} from '@material-ui/core';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import InvoiceIcon from './components/InvoiceIcon';

import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import TablePaginator from '../../../components/tableComponents/TablePaginator/TablePaginator';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import homeStyles from '../../../constants/homeStyles';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { IAppState } from '../../../store/rootDuck';
import { getFio } from '../users/users/utils';
import { useGetInvoices } from './hooks/useGetInvoices';
import { useStylesInvoicesPage } from './hooks/useStyles';
import { usePaidInvoice } from './hooks/usePaidInvoice';
import { IInvoice } from '../../../interfaces/projects';
import InvoiceModal from './components/InvoiceModal';
import { useGetInvoice } from '../settings/invoice/hooks/useGetInvoice';
import { useSearchInvoices } from './hooks/useSearchInvoices';
import AutocompleteIdInvoice from '../../../components/AutocompleteIdInvoice';
import { useGetTariffName } from '../projects/hooks/useGetTariffName';

const InvoicesPage: React.FC = () => {
  const classes = useStylesInvoicesPage();
  const { state } = useLocation();
  const homeClasses = homeStyles();
  const fm = useFormatMessage();
  const [invoiceModal, setInvoiceModal] = useState<IInvoice | null>(null);
  const [invoiceSelect, setInvoiceSelect] = useState<IInvoice | null>(null);
  const [status, setStatus] = useState<
    'not_paid' | 'paid' | 'archived' | 'all' | 'in_progress'
  >('all');
  const { fetchInvoices, loading, page, perPage, total, invoices } = useGetInvoices();
  const { fetchPaidInvoice, loading: loadingPaid, success } = usePaidInvoice();
  const getTarriffName = useGetTariffName();
  const { fetchGetInvoice, invoice } = useGetInvoice();
  const {
    fetchSearchInvoices,
    loading: loadingSearch,
    invoices: searchInvoices,
  } = useSearchInvoices();

  const currentInvoices = useMemo(() => (invoiceSelect ? [invoiceSelect] : invoices), [
    invoiceSelect,
    invoices,
  ]);

  const { me } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));

  const isVendor = useDefineUserRole(me, 'ROLE_VENDOR');

  setLayoutSubheader({
    title: fm('INVOICES.LIST'),
    breadcrumb: [],
  });

  setLayoutFooter({ show: true });

  useLayoutEffect(() => {
    fetchGetInvoice();
  }, []);

  useLayoutEffect(() => {
    fetchInvoices(1, 20, status);
  }, [state, isVendor, status]);

  useLayoutEffect(() => {
    success && fetchInvoices(1, 20, status);
  }, [success]);

  if (me && !me.is_admin && !isVendor) return <></>;

  if (!me || loading) return <Preloader />;

  const statusPaid: { [type: string]: string } = {
    paid: fm('ORDER.PAYMENT.STATUS.PAID'),
    not_paid: fm('ORDER.PAYMENT.STATUS.NOT_PAID'),
    archived: fm('ARCHIVE'),
    in_progress: fm('ORDER.TYPE.PROCCESS'),
  };

  return (
    <>
      <Row>
        <Col>
          <div className={classes.container}>
            <div className={classes.containerFilters}>
              <FormControl style={{ margin: 16, width: 200 }}>
                <InputLabel>{fm('BODY.TABLE.STATUS')}</InputLabel>
                <Select
                  value={status}
                  label={fm('BODY.TABLE.STATUS')}
                  onChange={e =>
                    setStatus(
                      e.target.value as
                        | 'paid'
                        | 'not_paid'
                        | 'archived'
                        | 'all'
                        | 'in_progress'
                    )
                  }
                >
                  <MenuItem value='all'>{fm('ACTIVITY.ALL')}</MenuItem>
                  <MenuItem value='paid'>{fm('ORDER.PAYMENT.STATUS.PAID')}</MenuItem>
                  <MenuItem value='not_paid'>{fm('ORDER.PAYMENT.STATUS.NOT_PAID')}</MenuItem>
                  <MenuItem value='archived'>{fm('ARCHIVE')}</MenuItem>
                  <MenuItem value='in_progress'>{fm('ORDER.TYPE.PROCCESS')}</MenuItem>
                </Select>
              </FormControl>
              <AutocompleteIdInvoice
                searchInvoices={searchInvoices}
                fetchSearchInvoices={fetchSearchInvoices}
                setInvoiceSelect={setInvoiceSelect}
                loadingSearch={loadingSearch}
                invoiceSelect={invoiceSelect}
                inputClassName={{ width: '100%' }}
              />
            </div>
            {!currentInvoices.length ? (
              <Typography
                style={{ marginLeft: 20 }}
                className={classes.empty}
                component='h5'
                variant='h5'
              >
                {fm('COMMON.LIST.EMPTY')}
              </Typography>
            ) : (
              <Card className={classes.tableContainer}>
                <CardContent className={homeClasses.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>{fm('USER.TABLE.ID')}</SHeadTableCell>
                        <SHeadTableCell>{fm('PROJECTS.ITEM.TITLE')}</SHeadTableCell>
                        {!isVendor && (
                          <>
                            <SHeadTableCell>{fm('LEGAL.ENTITY')}</SHeadTableCell>
                            <SHeadTableCell>{fm('USER.EDIT.FIO')}</SHeadTableCell>
                          </>
                        )}
                        <SHeadTableCell>{fm('USER.TABLE.TARIFF')}</SHeadTableCell>
                        <SHeadTableCell>{fm('DURATION')}</SHeadTableCell>
                        <SHeadTableCell>{fm('ORDER.TABLE.PRICE')}</SHeadTableCell>
                        <SHeadTableCell>{fm('BODY.TABLE.STATUS')}</SHeadTableCell>
                        <SHeadTableCell>{fm('PRODUCT.TABLE.ACTIONS')}</SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentInvoices.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id || ''}
                          </TableCell>
                          <TableCell>{item.company.name || ''}</TableCell>
                          {!isVendor && !state?.user && (
                            <>
                              <TableCell>{item.user?.company_name || ''}</TableCell>
                              <TableCell>{item.user ? getFio(item.user) : ''}</TableCell>
                            </>
                          )}
                          <TableCell>
                            {item.tariff
                              ? `${getTarriffName(item.tariff.id)} (${
                                  item.tariff.users_num
                                } ${fm('USERS').toLocaleLowerCase()})`
                              : ''}
                          </TableCell>
                          <TableCell>{`${item.tariff_days} ${fm('DAYS')}`}</TableCell>
                          <TableCell>{item.price ? `${item.price} czk` : ''}</TableCell>
                          <TableCell>
                            {me.is_admin ? (
                              <>
                                <Select
                                  value={item.status}
                                  onChange={e => {
                                    if (e.target.value === 'in_progress') return;
                                    fetchPaidInvoice(
                                      item.id,
                                      e.target.value as 'paid' | 'not_paid' | 'archived'
                                    );
                                  }}
                                >
                                  <MenuItem value='paid'>
                                    {fm('ORDER.PAYMENT.STATUS.PAID')}
                                  </MenuItem>
                                  <MenuItem value='not_paid'>
                                    {fm('ORDER.PAYMENT.STATUS.NOT_PAID')}
                                  </MenuItem>
                                  <MenuItem value='archived'>{fm('ARCHIVE')}</MenuItem>
                                  <MenuItem value='in_progress' hidden>
                                    {fm('ORDER.TYPE.PROCCESS')}
                                  </MenuItem>
                                </Select>
                              </>
                            ) : (
                              statusPaid[item.status]
                            )}
                          </TableCell>
                          <TableCell>
                            <>
                              {/* {me.is_admin && item.status !== 'paid' && (
                                <Button
                                  color='primary'
                                  variant='contained'
                                  disabled={loadingPaid}
                                  onClick={() => fetchPaidInvoice(item.id)}
                                >
                                  {fm('ORDER.PAYMENT.STATUS.PAID')}
                                </Button>
                              )} */}
                              <IconButton
                                color='primary'
                                aria-label='previous'
                                onClick={() => setInvoiceModal(item)}
                              >
                                <InvoiceIcon size='24' />
                              </IconButton>
                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {!invoiceSelect && (
                      <TableFooter>
                        <TableRow>
                          <TablePaginator
                            page={page}
                            realPerPage={invoices.length}
                            perPage={perPage}
                            total={total}
                            fetchRows={(data: any) =>
                              fetchInvoices(data.page, data.perPage, status)
                            }
                            label={fm('INVOICES.PAGE')}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </CardContent>
              </Card>
            )}
          </div>
        </Col>
      </Row>
      <InvoiceModal
        invoice={invoiceModal}
        handleClose={() => setInvoiceModal(null)}
        open={!!invoiceModal}
        settingInvoice={invoice}
      />
    </>
  );
};

export default InvoicesPage;
