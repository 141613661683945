import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';
import classNames from 'classnames';
import EditIcon from './EditIcon';
import homeStyles from '../../../../constants/homeStyles';
import { IInvoice, IProject } from '../../../../interfaces/projects';
import { useFormatMessage } from '../../../../hooks';
import { useStylesProjectsPage } from '../hooks/useStyles';
import { useGetTariffName } from '../hooks/useGetTariffName';
import InvoiceIcon from '../../invoices/components/InvoiceIcon';

interface IProps {
  item: IProject;
  isAdmin: boolean;
  getPro: () => void;
  setInvoiceModal: (value: IInvoice | null) => void;
  requestConnect: (projectId: number) => void;
}
const ItemProject = ({ item, isAdmin, getPro, setInvoiceModal, requestConnect }: IProps) => {
  const classes = useStylesProjectsPage();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const getTarriffName = useGetTariffName();

  const statusPaid: { [type: string]: string } = {
    paid: fm('ORDER.PAYMENT.STATUS.PAID').toLowerCase(),
    not_paid: fm('ORDER.PAYMENT.STATUS.NOT_PAID').toLowerCase(),
    archived: fm('ARCHIVE').toLowerCase(),
    in_progress: fm('ORDER.TYPE.PROCCESS').toLowerCase(),
  };

  const statusProject: { [type: string]: string } = {
    inactive: fm('PARTICIPATION.TABLE.ACTIVE_FALSE').toLowerCase(),
    active: fm('PARTICIPATION.TABLE.ACTIVE_TRUE').toLowerCase(),
    in_progress: fm('STATUS.SETUP').toLowerCase(),
  };

  return (
    <div className={classes.itemContent}>
      <Card className={classes.card} elevation={3}>
        <CardContent style={{ paddingBottom: 10 }} className={homeClasses.tableContainer}>
          <div className={classes.title_container}>
            <div className={classes.wrapper}>
              <div className={classes.title_section}>
                <Typography className={classes.titleName}>{item.name}</Typography>

                <Typography className={classes.subTitle}>
                  {item.domain || ''}

                  {(isAdmin || (item.active && item.domain)) && (
                    <Tooltip title={fm('PROJECTS.BTN')}>
                      <IconButton
                        color='primary'
                        aria-label='previous'
                        component='a'
                        href={item.domain ? `https://${item.domain}` : undefined}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>

                <div className={classes.statusContent}>
                  <Box
                    className={classNames(
                      classes.statusCheck,
                      // @ts-ignore
                      classes[item.status]
                    )}
                  />

                  <Typography className={classes.titleStatus}>
                    {fm('PROJECTS.ITEM.STATUS')} - {statusProject[item.status]}
                  </Typography>

                  {item.current_tariff &&
                    item.current_tariff.tariff?.id !== 7 &&
                    item.pay_status && (
                      <div className={classes.containerPaidStatus}>
                        <Box
                          className={classNames(
                            classes.statusCheck,
                            // @ts-ignore
                            classes[item.pay_status]
                          )}
                        />

                        <Typography className={classes.titleStatus}>
                          {fm('PAYMENT').toLowerCase()} - {statusPaid[item.pay_status]}
                        </Typography>
                      </div>
                    )}
                </div>
              </div>

              <div className={classes.tariffContent}>
                <div className={classes.tariffContainer}>
                  <Typography className={classes.titleName}>
                    {getTarriffName(item.current_tariff?.tariff?.id)}
                  </Typography>

                  <Typography className={classes.titleTariff}>
                    {fm('PROJECTS.ITEM.TARIFF')}
                  </Typography>
                </div>

                <Typography className={classes.subTitle}>
                  {item.current_tariff?.stop_datetime
                    ? fm('PROJECTS.ITEM.DATE.TARIFF')
                    : fm('PROJECTS.ITEM.STOPDATE')}{' '}
                  {item.current_tariff?.stop_datetime &&
                    moment(item.current_tariff?.stop_datetime).format('DD.MM.YYYY')}
                </Typography>

                {item.name.toLowerCase() !== 'demo' && item.pay_status !== 'in_progress' && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={getPro}
                    className={classes.getBtn}
                  >
                    {fm('PROJECTS.ITEM.BTN')}
                  </Button>
                )}
                {item.name.toLowerCase() === 'demo' && !item.request_demo && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => requestConnect(item.id)}
                    className={classes.getBtn}
                  >
                    {fm('REQUEST.CONNECT')}
                  </Button>
                )}
              </div>
            </div>

            <div className={classes.iconContent}>
              <Tooltip title={fm('edit')}>
                <IconButton
                  color='primary'
                  aria-label='previous'
                  onClick={() => navigate(`/projects/edit/${item.id}`)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              {/* <Typography className={classes.editBtn}>{fm('edit')}</Typography> */}
              {item.invoice && item.pay_status !== 'archived' && (
                <Tooltip title={fm('INVOICE.ID')}>
                  <IconButton
                    color='primary'
                    aria-label='previous'
                    onClick={() => setInvoiceModal(item.invoice)}
                  >
                    <InvoiceIcon size='24' />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ItemProject;
