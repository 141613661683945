import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IUser, IUserAddProps } from '../../../../../interfaces/user';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { cleanPhone, getDigitCountFromMask } from '../../../../../utils/utils';

// const getUserAttrLanguages = (attributes: TUserAttributes) => {
//   return attributes.filter(el => el.type === 'languages').map(el => el.value);
// };

// const getUserAttrCountry = (attributes: TUserAttributes) => {
//   const countries = attributes.filter(el => el.type === 'country');
//   return countries[0]?.value || '';
// };

export const getInitialValues = (
  user: IUser | null,
  countryData: any,
  role:
    | 'ROLE_BUYER'
    | 'ROLE_MANAGER'
    | 'ROLE_VENDOR'
    | 'ROLE_VENDOR_STAFF'
    | 'ROLE_CONTENT_MANAGER'
    | 'ROLE_ADMIN'
) => ({
  // login: user?.login || '',
  email: user?.email || '',
  phone: user?.phone?.substring(1) || '',
  password: '',
  password2: '',
  role: user?.roles[0] || role || '',
  // company: user?.company || null,
  active: user?.active || false,
  // companyInn: '',
  // languages: user?.attributes ? getUserAttrLanguages(user.attributes) : [],
  // country: user?.attributes ? getUserAttrCountry(user.attributes) : '',
  first_name: user?.first_name || '',
  last_name: user?.last_name || '',
  middle_name: user?.middle_name || '',
  phoneNumbers: user?.phone_numbers
    ? cleanPhone(user.phone_numbers, `+${countryData?.code || '7'}`)
    : '',
  companyName: user?.company_name || '',
  ico: user?.ico || '',
  tin: user?.tin || '',
  vat: user?.vat || '',
  accountNumber: user?.account_number || '',
  paymentDetails: user?.payment_details || '',
  // categories: user?.categories ? user?.categories?.map(i => i.category.id!.toString()) : [],
});

export const useFormikUserEdit = (
  id: number | undefined | string,
  user: IUser | null,
  edit: any,
  add: any,
  countryData: any,
  role:
    | 'ROLE_BUYER'
    | 'ROLE_MANAGER'
    | 'ROLE_VENDOR'
    | 'ROLE_VENDOR_STAFF'
    | 'ROLE_CONTENT_MANAGER'
    | 'ROLE_ADMIN'
) => {
  const fm = useFormatMessage();
  // const isVendorStaff = useDefineUserRole(user, 'ROLE_VENDOR_STAFF');
  const userRoleVendor = useDefineUserRole(user, 'ROLE_VENDOR');

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(user, countryData, role),
    validationSchema: Yup.object().shape({
      // login: Yup.string().required(fm('AUTH.INPUT.LOGIN_REQUIRED')).nullable(),
      email: Yup.string()
        .required(fm('USER.EDIT.EMAIL.REQUIRED'))
        .email(fm('USER.EDIT.EMAIL.INCORRECT'))
        .nullable(),
      // password2: Yup.string().test(
      //   'password2',
      //   fm('USER.EDIT.PASSWORD.SIMILAR'),
      //   function test(value) {
      //     // eslint-disable-next-line react/no-this-in-sfc
      //     return this.parent.password === value;
      //   }
      // ),
      // password: !id ? Yup.string().required(fm('USER.EDIT.PASSWORD.REQUIRED')) : Yup.string(),
      role: Yup.string().required(fm('USER.EDIT.ROLE.REQUIRED')),
      last_name: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
      first_name: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
      phoneNumbers: Yup.string().test(
        'length-match',
        fm('AUTH.VALIDATION.REQUIRED_FIELD'),
        value => {
          return value
            ? value.length >= getDigitCountFromMask(countryData?.mask || '')
            : false;
        }
      ),
    }),

    onSubmit: values => {
      const newValues =
        userRoleVendor || values.role === 'ROLE_VENDOR'
          ? {
              companyName: values.companyName,
              ico: values.ico,
              tin: values.tin,
              vat: values.vat,
              accountNumber: values.accountNumber,
              paymentDetails: values.paymentDetails,
            }
          : {};
      if (id) {
        const submitValues = { ...values };
        if (!submitValues.password.trim()) {
          // @ts-ignore
          delete submitValues.password;
          // @ts-ignore
          delete submitValues.password2;
        }

        edit({
          id: +id,
          data: {
            ...submitValues,
            phone: submitValues.phone
              ? `${countryData?.code || '7'}${submitValues.phone}`
              : '',
            countryCode: countryData.code || '7',
            ...newValues,
            // categoriesIds:
            //   // eslint-disable-next-line no-nested-ternary
            //   role === 'ROLE_VENDOR_STAFF' || isVendorStaff
            //     ? values.categories.length > 0
            //       ? values.categories.join()
            //       : ''
            //     : undefined,
            // categories: undefined,
          },
        });
      } else {
        const addData: IUserAddProps = {
          ...values,
          roles: [values.role],
          countryCode: countryData.code || '7',
          ...newValues,
          // categoriesIds:
          //   // eslint-disable-next-line no-nested-ternary
          //   role === 'ROLE_VENDOR_STAFF' || isVendorStaff
          //     ? values.categories.length > 0
          //       ? values.categories.join()
          //       : ''
          //     : undefined,
          // categories: undefined,
        };
        add(addData);
      }
    },
  });
};
